import React from 'react';
import update from 'immutability-helper';
import StoreVisitService from '../services/storeVisit_service';
import Helpers from '../helpers';
import '../styles/common.css';
import Button from '@material-ui/core/Button';
import Dispatcher  from '../dispatcher';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    TimePicker,
    MuiPickersUtilsProvider,
  } from '@material-ui/pickers';

const styles = theme => ({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
      width: 300,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  });

class Duration extends React.Component {
    
    constructor(props) {
        super(props);
    }

    inTimeChanged(value) {
        this.props.changeInTime(value);
    }

    outTimeChanged(value) {
        this.props.changeOutTime(value);
    }

    render() {
        return(
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <TimePicker
                        style={{width: "100%"}}
                        value={this.props.data.checklistSubmission.in_time} onChange={ (e) => this.inTimeChanged(e) } label="IN TIME" inputVariant="outlined" />
                    <br />
                    <br />
                    <TimePicker 
                        style={{width: "100%"}}
                        value={this.props.data.checklistSubmission.out_time} onChange={ (e) => this.outTimeChanged(e) } label="OUT TIME" todayLabel="now" inputVariant="outlined"/>
                </div>
            </MuiPickersUtilsProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.data
    }
        
}

const changeInTime = Dispatcher.setInTime;
const changeOutTime = Dispatcher.setOutTime;

export default connect(mapStateToProps, { changeInTime, changeOutTime })(withStyles(styles)(Duration));


import update from 'immutability-helper';
//import ChecklistService from './services/checklist_service';
import StoreVisitService from './services/storeVisit_service';
import Helpers from './helpers';
import { declareFunction } from '@babel/types';


// const initialState = {
//     data: {
//         checklistSubmission: {
//             answers: {},
//             geodata: {},
//         }
//     }
// }

const rootReducer = (state, action) => {
    if (action.type === 'ADD_ANSWER') {
        const newState = {...state};
        newState.data.checklistSubmission.answers[action.payload.labelId] = action.payload.answer;
        return newState;

    } else if (action.type === 'VALUE_CHANGED') {
        
        const field = action.payload.field;
        const value = action.payload.value;

        const updateRequest = {
            data: { checklistSubmission: {answers: {} }}
        };
        updateRequest.data.checklistSubmission.answers[action.payload.labelId] = {};
        updateRequest.data.checklistSubmission.answers[action.payload.labelId][field] = {$set: value};

        const newState = update(state, updateRequest);

        return newState;
        
    } else if (action.type === 'IMAGES_UPLOADED') {
        //const newImages = [...state.data.checklistSubmission.images, ...action.payload];
        const newState = update(state, { data: {checklistSubmission: {images: {$set: action.payload} } } });
        console.log(newState);
        return newState;

    } else if (action.type === 'IMAGE_REMOVED') {
        const newState = update(state, {
            data: {
                checklistSubmission: {
                    images: {
                        $splice: [[action.payload.index, 1]]
                    } 
                } 
            } 
        });
        console.log(newState);
        return newState;
    } else if (action.type === 'STORE_CATEGORY') {
        const x = {};
        x[action.payload.categoryId] = action.payload;

        const newState = update(state, {
            data: {
                $merge: x
            }
        });
        return newState;

    } else if (action.type === 'CHANGE_TICKET') {
        const q = {};
        q[action.payload.categoryId] = { $set: action.payload };

        const newState = update(state, {
            data: q
        });

        return newState;
    } else if (action.type === 'SELECT_STORE') {
        
        const newState = update(state, {
            data: {
                checklistSubmission: {
                    store: {
                        $set: action.payload
                    } 
                } 
            } 
        });
        console.log(newState);
        return newState;
    } else if (action.type === 'SET_CHECKLIST_ID') {
        const newState = update(state, {
            data: {
                checklistSubmission: {
                    checklist_id: {
                        $set: action.payload
                    } 
                } 
            } 
        });
        console.log(newState);
        return newState;
    } else  if (action.type === 'LOGOUT') {
        return {
            data: {
                checklistSubmission: {
                    checklist_id: null,
                    store: null,
                    answers: {},
                    geodata: {},
                    images: [],
                    categories:[]
                },
                
            }
        };
    } else if (action.type === 'CHANGE_IN_TIME') {
        const newState = update(state, {
            data: {
                checklistSubmission: {
                    in_time: { $set: action.payload }
                } 
            } 
        });
        return newState;

    } else if (action.type === 'CHANGE_OUT_TIME') {
        const newState = update(state, {
            data: {
                checklistSubmission: {
                    out_time: { $set: action.payload }
                } 
            } 
        });
        
        return newState;
    } 
    else {
        console.log(action);
        return state;
    }
}

function findTicketAndChange() {

}

export default rootReducer;

import React from 'react';
import Dispatcher  from '../dispatcher';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import '../styles/common.css';
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import RemarkModal from './remarkModal';


class LabelForm extends React.Component {

    changeHandler(field, value) {
        this.props.valueChanged(this.props.label._id, field, value);
    }

    toogledCheck() {
        return !this.props.data.checklistSubmission.answers[this.props.label['_id']].checked;
    }

    toogledTicket() {
        return !this.props.data.checklistSubmission.answers[this.props.label['_id']].ticket;
    }
    
    render() {
        
        const answer = this.props.data.checklistSubmission.answers[this.props.label['_id']];
        return (
            <TableRow>
                <TableCell style={{paddingRight: 1}}>
                    {this.props.label.label_name}
                </TableCell>
                <TableCell padding="checkbox" style={{textAlign: "center"}}>
                    <Checkbox checked={answer.checked} onChange={(e) => {this.changeHandler('checked', this.toogledCheck()) }} />
                </TableCell>
                <TableCell padding="checkbox" style={{textAlign: "center"}}>
                <Switch
                    checked={answer.ticket}
                    onChange={(e) => {this.changeHandler('ticket', this.toogledTicket()) }}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                </TableCell>
                <TableCell>
                    {/* <TextField
                        id="standard-multiline-flexible"
                        multiline
                        rowsMax="4"
                        value={answer.remarks}
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => {this.changeHandler('remarks', e.target.value) }}
                    /> */}
                    <RemarkModal changeHandler = {this.changeHandler} answer = {answer} labelId = {this.props.label._id}></RemarkModal>
                </TableCell>
            </TableRow>
        );
    }

   
}

const mapStateToProps = state => {
    return {
        data: state.data
    }
        
}

const addAnswer = Dispatcher.addAnswer;
const valueChanged = Dispatcher.valueChanged;



export default connect(mapStateToProps, { addAnswer, valueChanged })(LabelForm);
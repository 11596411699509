import React from 'react';
import withAuth from './components/withAuth';
import ElevateAppBar from './components/customAppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Checklist from './components/checklist'
import AuthService from './services/AuthService';
import store from './store';
import { Provider } from 'react-redux';




class App extends React.Component {

  constructor(props) {
    super(props);

    this.Auth = new AuthService();
  }
  
  logoutHandler() {
    this.Auth.logout();
    this.props.history.replace('/login');
  }
  
  render() {
    return (
      <Provider store={store}>
        <div style={{position: "sticky"}}>
        <CssBaseline />
        
          <ElevateAppBar history={this.props.history}>
            <div>
              <Checklist history={this.props.history}></Checklist>
            </div>
          </ElevateAppBar>
        
        </div>
      </Provider>
    );
  }
}

export default withAuth(App);



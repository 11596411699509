import React from 'react';
import update from 'immutability-helper';
import StoreVisitService from '../services/storeVisit_service';
import Helpers from '../helpers';
import '../styles/common.css';
import Button from '@material-ui/core/Button';
import Dispatcher  from '../dispatcher';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
      width: 300,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  });

class Confirmation extends React.Component {

    
    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            showError: false
        };
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        console.log(this);
        this.setState({showError: false, isSaving: false});
    }

    handleOpen() {
        this.setState({showError: true});
    }
    
    

    render() {
        const { classes } = this.props;
        return (
            <div>
                { this.state.isSaving && <LinearProgress /> }

                <h3>Are you sure you want to submit the checklist form?</h3>
                <Button variant="outlined" color="primary" onClick = {e => this.submitForm() }>
                    Submit
                </Button>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.showError}
                    onClose={this.handleClose}
                    
                >
                    <div className={classes.paper}>
                        <h2 id="simple-modal-title">Submission not vaild</h2>
                        <div id="simple-modal-description">
                            <p>
                                You have not checked all the checkpoints. Please make sure you have checked all the checkpoints in the checklist and resubmit.
                            </p>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    validate(submission) {
        for(const answer of submission.answers) {

            console.log(answer);
            console.log(!answer.checked);

            if (!answer.checked) {
                return false;
            }
        }

        if (!submission.in_time || !submission.out_time) return false;

        return true;
    }

    submitForm() {
        this.setState({ isSaving: true });
        const submission = update(this.props.data.checklistSubmission, {answers: {$set: []}});

        for (let [key, value] of Object.entries(this.props.data.checklistSubmission.answers)) {
            submission.answers.push({...value, label_id: key});
        }
        console.log(submission);

        if(this.validate(submission)) {
            console.log("Adding to server...");
            this.saveDataToServer(submission);
        } else {
            this.handleOpen();
            //this.setState({isSaving: false});
        }
        
    }

    async saveDataToServer(submission) {
        const storeVisitService = new StoreVisitService();
            
            const imgs = [];
    
            await Helpers.asyncForEach(submission.images, async (imageFile) => {
                try {
                    const imgCompressed = await Helpers.compressImage(imageFile);
                    imgs.push(imgCompressed);
    
                } catch (ex) {
                    console.log("Case kheyeche");
                }
            });
            
            const imgUrls = await storeVisitService.upload(imgs);
            
            submission['imgUrls'] = imgUrls;
            delete submission.images;
            console.log(submission);
            await storeVisitService.post(submission);
            console.log("Khusi thako!");
            this.props.history.push('/success');
    }
}

const mapStateToProps = state => {
    return {
        data: state.data
    }
        
}

//const submit = Dispatcher.submit;

export default connect(mapStateToProps, { })(withStyles(styles)(Confirmation));


import React from 'react';
import ImageUploader from 'react-images-upload';
import Box from '@material-ui/core/Box';
import dispatcher from '../dispatcher';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

class SnapCam extends React.Component {

    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
    }

    onDrop(pictures) {
        console.log(pictures);
        this.props.imagesUploaded(pictures);
    }

    render() {
        return(
            <div>
                <ImageUploader
                    withIcon={true}
                    withPreview={true}
                    buttonText='Take photo'
                    onChange={this.onDrop}
                    imgExtension={['.jpg', '.gif', '.png', '.gif']}
                    label = "Upload Images"
                    maxFileSize={999999999}
                    singleImage={false}
                    defaultImages={this.props.data.checklistSubmission.images.map(image => image? URL.createObjectURL(image) :null)}

                />
                {/* <div>
                    { this.props.data.checklistSubmission.images.length > 0 &&
                        this.props.data.checklistSubmission.images.map(
                            (picture, index) => this.PreviewImage(picture, index)) 
                    }
                </div> */}
            </div>
        );
    }

    PreviewImage(picture, index) {
        return (
            <Box component="span" display="block" key = {index} style={{padding: 15}}>
                <img src = {URL.createObjectURL(picture)} height="100%" width="100%" alt=""/>
                <Box component="span" display="block">
                    <IconButton aria-label="delete" onClick={(e) => { this.props.removeImage(index) }}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Box>
            </Box>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.data
    }
}

const imagesUploaded = dispatcher.imagesUploaded;
const removeImage = dispatcher.removeImage;

export default connect(mapStateToProps, {imagesUploaded, removeImage})(SnapCam);
import AuthService from "./AuthService";
import Settings from  './service_data';

export default class TlStoreService {
    constructor() {
        this.Auth = new AuthService(Settings.BASE_URL);
    }

    // async getDefaultChecklist() {
    //     const checklist = await this.Auth.fetch(`${Settings.BASE_URL}/checklists/`, {
    //         mode: 'cors', // no-cors, *cors, same-origin
    //     });
    //     console.log(checklist);
    //     return checklist;
    // }

    async get() {
        const response = await this.Auth.fetch(`${Settings.BASE_URL}/stores`, {
            mode: 'cors',
            method: 'GET',
        });
        console.log(response);
        return response;
    }

    async post(data) {
        const response = await this.Auth.fetch(`${Settings.BASE_URL}/storevisit`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(data)
        });
        console.log(response);
    }
}
import React from 'react';
import withAuth from './components/withAuth';
import ElevateAppBar from './components/customAppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import SelectStore from './components/selectStore';
import AuthService from './services/AuthService';
import store from './store';
import { Provider } from 'react-redux';

class StoreParent extends React.Component {

  constructor(props) {
    super(props);

    this.Auth = new AuthService();
  }
  
  logoutHandler() {
    this.Auth.logout();
    this.props.history.replace('/login');
  }
  
  render() {
    return (
      <Provider store={store}>
        <div style={{position: "sticky", height: "100%", width: "100%"}}>
        <CssBaseline />
        
          <ElevateAppBar history={this.props.history} style={{height: "100%"}}>
            <div style={{marginLeft: "5px", marginRight: "5px", marginTop: "5px", marginBottom: "5px"}}>
              <SelectStore history={this.props.history}></SelectStore>
            </div>
          </ElevateAppBar>
        
        </div>
      </Provider>
    );
  }
}

export default withAuth(StoreParent);



import AuthService from "./AuthService";
import Settings from  './service_data';

export default class StoreVisitService {
    constructor() {
        this.Auth = new AuthService(Settings.BASE_URL);
    }

    // async getDefaultChecklist() {
    //     const checklist = await this.Auth.fetch(`${Settings.BASE_URL}/checklists/`, {
    //         mode: 'cors', // no-cors, *cors, same-origin
    //     });
    //     console.log(checklist);
    //     return checklist;
    // }

    async upload(data) {
        
        try {
            let formData = new FormData();
            
            data.forEach(imgBlob => {
                formData.append("images", imgBlob, imgBlob.filename);
            });
            
            console.log(formData);

            const response = await this.Auth.fetch(`${Settings.BASE_URL}/uploadfile`,{
                    mode: 'cors',
                    method: 'POST',
                    body: formData,
                
                },
                {
                    
                    'Accept': 'application/json'
                }
            );
            
            if(response.error) {
                throw response.error;
            } else {
                return response.imageUrls;
            }
        } catch (ex) {
            console.log(ex);
        }
        

        
    }

    async post(data) {
        const response = await this.Auth.fetch(`${Settings.BASE_URL}/storevisit`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(data)
        });
        console.log(response);
    }
}
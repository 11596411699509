import React from 'react';
import Button from '@material-ui/core/Button';
import ElevateAppBar from './customAppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import AuthService from '../services/AuthService';
import { textAlign } from '@material-ui/system';
import store from '../store';

export class SuccessPage extends React.Component {
    constructor(props) {
        super(props);
        this.Auth = new AuthService();
    }

    render() {
        return (
            <div>
                <CssBaseline />
                <ElevateAppBar logoutHandler={(e) => this.logoutHandler()} >
                    <div style={{ textAlign: "center" }}>
                        <p style={{ fontSize: 20 }}>Thank you for completing the store visit.</p>
                        <Button variant="outlined" color="secondary" onClick={e => this.logoutHandler()}>
                            Logout
                        </Button>
                    </div>
                </ElevateAppBar>
            </div>
        );
    }

    logoutHandler() {
        store.dispatch({
          type: 'LOGOUT'
        });
        this.Auth.logout();
        this.props.history.replace('/login');
      }
}
const addAnswer = (labelId, answer) => dispatch => {
    dispatch({
        type: 'ADD_ANSWER',
        payload: {
            labelId,
            answer
        }
    });
}

const valueChanged = (labelId, field, value) => dispatch => {
    dispatch({
        type: 'VALUE_CHANGED',
        payload: {
            labelId,
            field,
            value
        }
    });
}

const imagesUploaded = (images) => dispatch => {
    dispatch({
        type: 'IMAGES_UPLOADED',
        payload: images
    });
}

const removeImage = (index) => dispatch => {
    dispatch({
        type: 'IMAGE_REMOVED',
        payload: {index}
    });
}

const addCategory = (category) => dispatch => {
    // categories.forEach(element => {
    //     element.ticket_flag = false;
    //     element.ticket_text = "";
    // });

    dispatch({
        type: 'STORE_CATEGORY',
        payload: category
    });
}

const changeTicket = (categoryId, ticketFlag, ticketText) => disptach => {
    disptach({
        type: 'CHANGE_TICKET',
        payload: {
            categoryId: categoryId,
            ticket_flag: ticketFlag,
            ticket_text: ticketText
        }
    });
}

const selectStore = (store) => dispatch => {
    dispatch({
        type: 'SELECT_STORE',
        payload: store
    });
}

const setChecklistId = (checklisId) => dispatch => {
    dispatch({
        type: 'SET_CHECKLIST_ID',
        payload: checklisId
    });
}

const logout = () => dispatch => {
    dispatch({
        type: 'LOGOUT'
    });
}

const setInTime = (time) => dispatch => {
    dispatch({
        type: 'CHANGE_IN_TIME',
        payload: time
    });
}

const setOutTime = (time) => dispatch => {
    dispatch({
        type: 'CHANGE_OUT_TIME',
        payload: time
    });
}

export default { addAnswer, valueChanged, imagesUploaded, removeImage, addCategory, changeTicket, selectStore, setChecklistId, logout, setInTime, setOutTime };
import AuthService from "./AuthService";
import Settings from  './service_data';

export default class ChecklistService {
    constructor() {
        this.Auth = new AuthService(Settings.BASE_URL);
    }

    async getDefaultChecklist() {
        const checklist = await this.Auth.fetch(`${Settings.BASE_URL}/checklists/`, {
            mode: 'cors', // no-cors, *cors, same-origin
        });
        console.log(checklist);
        return checklist;
    }

    async getChecklist(id) {
        const checklist = await this.Auth.fetch(`${Settings.BASE_URL}/checklists/?id=${id}`, {
            mode: 'cors', // no-cors, *cors, same-origin
        });
        console.log(checklist);
        return checklist;
    }

    async postChecklist(data) {
        const response = await this.Auth.fetch(`${Settings.BASE_URL}/checklists`, {
            mode: 'cors',
            method: 'POST',
            body: data
        }, {
            'Accept': 'application/json'
        });
        console.log(response);
    }
}
import imageCompression from 'browser-image-compression';

function toFormData(obj, form, namespace) {
    let fd = form || new FormData();
    let formKey;
    
    for(let property in obj) {
      if(obj.hasOwnProperty(property) && obj[property]) {
        if (namespace) {
          formKey = namespace + '[' + property + ']';
        } else {
          formKey = property;
        }
       
        // if the property is an object, but not a File, use recursivity.
        if (obj[property] instanceof Date) {
          fd.append(formKey, obj[property].toISOString());
        }
        else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
          toFormData(obj[property], fd, formKey);
        } else { // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }
    
    return fd;
  }

function getBase64(file) {
    console.log(file);
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.readAsDataURL(file);
        // reader.onload = function () {
        //     cb(reader.result)
        // };
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject();
        // reader.onerror = function (error) {
        //     console.log('Error: ', error);
        // };

    });
}

async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

async function compressImage(file) {
  console.log(file);

  const options = { 
    maxSizeMB: 1,          // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 600,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    useWebWorker: true,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    // maxIteration: number        // optional, max number of iteration to compress the image (default: 10)
  }

  const fileCompressed = await imageCompression(file, options)
  console.log(fileCompressed);

  return await fileCompressed;
}

  export default { toFormData , getBase64, asyncForEach, compressImage };
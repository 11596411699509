import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {
    data: {
        checklistSubmission: {
            checklist_id: null,
            store: null,
            answers: {},
            geodata: {},
            images: [],
            categories:[],
            in_time: null,
            out_time: null
        },
        
    }
}

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware)
//   compose(
//     applyMiddleware(...middleware),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//   )
);

export default store;
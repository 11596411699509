import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Switch from '@material-ui/core/Switch';
// import TextField from '@material-ui/core/TextField';
import '../styles/common.css';
import LabelForm from './labelForm';



const useStyles = makeStyles(theme => ({
  root: {
    //maxWidth: 400,
    flexGrow: 1,

  },
  header: {
    //marginTop: 60,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 50,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    position: "sticky",
    top: 55,
    left: 0,
    right: 0,
    zIndex: 1000
  },
  img: {
    height: 255,
    //maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },

  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


function MyStep(props) {
  const step = props.step;
  const storeData = useSelector(state => state.data);
  const dispatch = useDispatch();
  console.log(storeData);
  const classes = props.classes;
  console.log(step);

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  // const [category, setCategory] = React.useState(step.category);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Paper square elevation={2} className={classes.header}>
        <Typography>{step.stepLabel}</Typography>

        {/* <Typography>
          {step.category && 
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            Ticket
          </Button>}
        </Typography> */}


      </Paper>
      <div className="padded">
        {
          step.category && categoryTable(step)
        }
        {
          !step.category && (step.component)
        }
        <br></br>
      </div>

      {/* {step.category &&
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Ticket</h2>
          <p id="simple-modal-description">
            <b>CATEGORY: {step.category.category_info.categoryName}</b>
          </p>
          <p>
          <Switch
            checked={storeData[step.category.category_info._id].ticket_flag}
            onChange={(e) => { 
              Dispatcher.changeTicket(step.category.category_info._id, 
                !storeData[step.category.category_info._id].ticket_flag, 
                storeData[step.category.category_info._id].ticket_text)(dispatch) }
            }
            value="checkedB"
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          </p>
          <p>
          <TextField
              id="standard-multiline-flexible"
              disabled={!storeData[step.category.category_info._id].ticket_flag}
              multiline
              rowsMax="4"
              value={storeData[step.category.category_info._id].ticket_text}
              onChange={(e) => { 
                Dispatcher.changeTicket(step.category.category_info._id, 
                  storeData[step.category.category_info._id].ticket_flag, 
                  e.target.value)(dispatch) }
              }
              margin="normal"
              variant="outlined"
              placeholder="Description"
          />
          </p>

        </div>
      </Modal>} */}

    </div>
  );

  
}


function categoryTable(step) {
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell className="table-header" style={{paddingRight: 1}}>Checkpoint</TableCell>
          <TableCell className="table-header" style={{paddingLeft: 1, paddingRight: 1}}>Checked</TableCell>
          <TableCell className="table-header">Ticket</TableCell>
          <TableCell className="table-header">Remarks</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          step.category.labels.map(label => <LabelForm label={label} className="label" key={label._id} />)
        }
      </TableBody>
    </Table>
  );
}

export default function TextMobileStepper(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.content.length;
  const selectedStore = useSelector(state => state.data.checklistSubmission.store)

  const handleNext = () => {
    setActiveStep(
      prevActiveStep => {
        switch(prevActiveStep) {
          default: return prevActiveStep + 1;
          case 0: return selectedStore ? prevActiveStep + 1 : prevActiveStep;
        }
        //return prevActiveStep + 1
      }
    );
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <MyStep step={props.content[activeStep]} classes={classes} history={props.history} />
      <MobileStepper
        className="bottomBar"
        steps={maxSteps}
        position="bottom"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1 || !selectedStore}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </div>
  );
}


const mapStateToProps = state => {
  return {
      data: state.data
  }
      
}

//const submit = Dispatcher.submit;

import React from 'react';
import TlStoreService from '../services/stores_service';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dispatcher  from '../dispatcher';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { textAlign } from '@material-ui/system';
import ElevateAppBar from './customAppBar';
import Button from '@material-ui/core/Button';


class SelectStore extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stores : [],
            selectedStore: null
        };

        this.loadStoreData();
    }

    async loadStoreData() {
        let service = new TlStoreService();
        let data = await service.get();
        this.setState({ stores: data.stores, selectedStore: null});
    }

    render() {
        //console.log(this.props.data.checklistSubmission.store.short_name);
        //this.state.selectedStore = this.props.data.checklistSubmission.store;
        return(
            
                

                this.state.stores && 
                <div>
                    <Autocomplete
                        id="combo-box-demo"
                        options={this.state.stores}
                        value={this.state.selectedStore}
                        getOptionLabel={option => option.short_name}
                        //style={{ width: 300 }}

                        renderInput={params => (
                            <TextField {...params} variant="outlined" fullWidth />
                        )}
                        onChange={(e, value) => { this.props.selectStore(value);} }
                        style={{ position: "absolute", bottom: "10px", left: "10px", right: "10px" }}
                    />


                    {
                        this.props.data.checklistSubmission.store &&
                        <div style={{textAlign: 'center', paddingTop: '10px'}}>
                            <h2>Current Store:</h2> 
                            <Typography>{this.props.data.checklistSubmission.store.short_name}</Typography>
                            
                        </div>
                    }

                    {
                        !this.props.data.checklistSubmission.store &&
                        <div style={{textAlign: 'center', paddingTop: '10px'}}>
                            <h2>Current Store:</h2> 
                            <Typography style={{color: 'red'}}>Please select a store!</Typography>
                            
                        </div>
                    }

                    {
                        this.props.data.checklistSubmission.store &&
                        <div style={{textAlign: 'center', paddingTop: '30px'}}>
                            <Button variant="contained" color="primary" onClick = {e => this.gotoApp() }>
                                Go Ahead!
                            </Button>
                            
                        </div>
                    }
                

                </div>
               
                
            
        );

    }

    gotoApp() {
        this.props.history.push('/');
    }
}

const mapStateToProps = state => {
    return {
        data: state.data
    }
        
}

const selectStore = Dispatcher.selectStore;

export default connect(mapStateToProps, { selectStore })(SelectStore);

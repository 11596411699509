import React from 'react';
import ChecklistService from '../services/checklist_service';
import LinearProgress from '@material-ui/core/LinearProgress';
import '../styles/common.css';
import TextMobileStepper from './custom_stepper';
import Dispatcher  from '../dispatcher';
import { connect } from 'react-redux';
import SnapCam from './snapcam';
import SelectStore from './selectStore';
import Confirmation from './confirmation';
import Duration from './duration';

class Checklist extends React.Component {

    constructor(props) {
        super(props);

        console.log("constructor called");
        console.log(this.props.data);
        
        this.state = {
            loading: false,
            checklist: null,
            categories: [],
            error: false,
            steps: []
        }
        
        this.service = new ChecklistService();
    }

    async loadData() {
        this.setState({ loading: true });
        const data = await this.service.getChecklist(this.props.data.data.checklistSubmission.store.defaultchecklistid);

        if(!data.error) {
            const checklist = data.checklist;
            const checklistId = data.checklist_meta._id;
            this.props.setChecklistId(checklistId);
            
            const categories = [];
            for (let [key, value] of Object.entries(checklist)) {
                console.log(key);
                categories.push(value);
            }
            let steps = categories.map(category => ({
                stepLabel: category.category_info.categoryName,
                category
            }));
            steps = this.addOtherPages(steps);
            this.prepareSubmissionDataInStore(categories);
            this.setState({ checklist, loading: false, categories, error: data.error, steps });
        } else {
            this.setState({ loading: false, error: data.error });
        }
        
    }

    addOtherPages(steps) {

        return [
            // {
            //     stepLabel: "Select Store",
            //     component:  <SelectStore></SelectStore>
            // },
            ...steps,
            {
                stepLabel: "Add Snaps",
                component: <SnapCam></SnapCam>
            },
            {
                stepLabel: "Duration",
                component: <Duration></Duration>
            },
            {
                stepLabel: "Confirm Your Submission",
                component: <Confirmation history={this.props.history}></Confirmation>
            }
        ]
    }

    prepareSubmissionDataInStore(categories) {
        categories.forEach(category => {
            category.labels.forEach(label => {
                const answer = {
                    categoryId: category.category_info['_id'],
                    checked: false,
                    remarks: "",
                    ticket: false,
                    label_name: label.label_name
                };
                this.props.addAnswer(label['_id'], answer);
            });
            const categoryTicket = {
                categoryId: category.category_info['_id'],
                ticket_flag: false,
                ticket_text: ""
            };
            
            this.props.addCategory(categoryTicket);
        });
    }

    componentDidMount() {
        if (!this.props.data.data.checklistSubmission.store) {
            console.log("redirecting");
            this.props.history.replace('/store');
        } else {
            this.loadData();
        }
    }

    render() {
        
        return (
            <div>
                
                {
                    this.state.loading && <LinearProgress />
                    // <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    //     <CircularProgress />
                    // </div>
                }
                {
                    !this.state.loading && this.state.checklist && 
                    <TextMobileStepper content={this.state.steps} history={this.props.history} />
                }

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const addAnswer = Dispatcher.addAnswer;
const addCategory = Dispatcher.addCategory;
const setChecklistId = Dispatcher.setChecklistId;

export default connect(mapStateToProps, {addCategory, addAnswer, setChecklistId})(Checklist);
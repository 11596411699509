import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux';
import Dispatcher from '../dispatcher';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function RemarkModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const label = useSelector(state => state.data.checklistSubmission.answers[props.labelId]);
  const dispatch = useDispatch();
  
  const valueChanged = useCallback(
    (labelId, field, value) => dispatch({
        type: 'VALUE_CHANGED',
        payload: {
            labelId,
            field,
            value
        }
      }),
    [dispatch]
  );

  const changeHandler = (field, value) => {
    console.log("Called");
    valueChanged(props.labelId, field, value);
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {!props.answer.remarks && <Button color="secondary" onClick={handleOpen}>Add</Button>}
      {props.answer.remarks && <Button color="primary" onClick={handleOpen}>Change</Button>}
      
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="simple-modal-title">Add Remark</h2>
          <div id="simple-modal-description">
          <TextField
              id="standard-multiline-flexible"
              multiline
              rowsMax="8"
              rows="4"
              value={label.remarks}
              margin="normal"
              variant="outlined"
              onChange={(e) => { changeHandler('remarks', e.target.value) }}
              style={{width: "100%"}}
          />
          </div>
        </div>
      </Modal>
    </div>
  );
}